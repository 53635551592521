import React from 'react'
import { Helmet } from 'react-helmet'
import config from '../../../config'
import { Fade } from 'react-reveal'
import { LoadableQuintMediaContainer } from '../LoadableComponents'

const UnderConstruction = ({
  data
}) => {
  if (!data) {
    return ('')
  }

  return (
    <>
      <Helmet>
        <title>{config.siteTitle}</title>
        <meta name='description' content={config.siteDescription} />
      </Helmet>
      <div
        className='content-container'
        style={{ backgroundColor: 'white', height: '100vh', justifyContent: 'space-evenly' }}>
        {data.title
          ? <Fade bottom><div className='quint-heading-big font-main color-primary-dark'>
            {data.title}
          </div></Fade>
          : ''}
        {data.media &&
          <LoadableQuintMediaContainer
            type={data.media.document.data.type} path={data.media.document.data.file.url}
            style={{
              height: '50%',
              width: '50%'
            }} />}
        {data.subtitle
          ? <Fade bottom><div className='quint-subtitle font-main color-primary'>
            {data.subtitle}
          </div></Fade>
          : ''}
      </div>
    </>
  )
}

export default UnderConstruction
