import React from 'react'
import { graphql } from 'gatsby'
import UnderConstruction from '../components/UnderConstruction'

const ProductPage = ({ data }) => {
  if (!data || !data.prismicUnderConstructionPage) {
    return ''
  }
  const { data: underConstructionData } = data.prismicUnderConstructionPage

  return (
    <UnderConstruction data={underConstructionData} />
  )
}

export default ProductPage

export const underConstructionQuery = graphql`
  query UnderConstruction($id: String!) {
    prismicUnderConstructionPage(id: {eq: $id}) {
      data {
        title
        subtitle
        media {
          document {
            ... on PrismicMedia {
              id
              data {
                file {
                  url
                }
                speed
                type
              }
            }
          }
        }
      }
    }
  }
`
